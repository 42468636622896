import { StatusesEnum } from '../../shared/enums/statuses.enum';

export const FILTER_TABS_CONFIG = [
  {
    icon: 'account_circle',
    label: 'Quoting',
    filterType: StatusesEnum.Quoting,
  },
  {
    icon: 'assignment_ind',
    label: 'Enrollment',
    filterType: StatusesEnum.Enrollment,
  },
  {
    icon: 'payments',
    label: 'Submitted',
    filterType: StatusesEnum.Submitted,
  },
  {
    icon: 'all_inbox',
    label: 'All Records',
  },
];

export const QUOTES_TABLE_COLUMNS = [
  'policyHolder',
  'group',
  'state',
  'startDate',
  'submittedDate',
  'status',
  'progress',
  'payment',
  'actions',
];

export const DEFAULT_PAGE_PARAMS = {
  PageIndex: 0,
  PageSize: 10,
  ByName: '',
  skipLoader: true,
  'SortSetting.SortedProperty': 'SubmissionDate.StartDate.Email',
  'SortSetting.SortDirection': 1,
};
